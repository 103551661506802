<template>
	<div class="Correct" style="height: 80vh;overflow: auto;" id="Correct" @mousedown="mousedown">
		<div style="position: absolute;bottom: 40px;padding: 20px;">
			<div>
				<el-button size="mini"  v-if="$store.state.rollShow"  v-preventReClick  @click="getRollShow()">滚动距离</el-button>
				<el-button size="mini"  v-else   v-preventReClick  @click="getRollShow()">滚动缩放</el-button>
			</div>
			<div style="margin-top: 20px;">
				<el-button size="mini" @click="previousPage()" v-preventReClick>{{$store.state.teachlanguagedata.previouspage}}</el-button>
			</div>
			<div style="margin-top: 20px;">
				<el-button size="mini" @click="nextPage()" v-preventReClick>{{$store.state.teachlanguagedata.nextpage}}</el-button>
			</div>
		</div>
		<div style="position: absolute;right: 60px;bottom: 60px;">
			<div>
				<el-button size="mini"  v-if="$store.state.rollShow"  v-preventReClick  @click="getRollShow()">滚动距离</el-button>
				<el-button size="mini"  v-else   v-preventReClick  @click="getRollShow()">滚动缩放</el-button>
			</div>
			<div style="margin-top: 20px;">
				<el-button size="mini" @click="previousPage()" v-preventReClick>{{$store.state.teachlanguagedata.previouspage}}</el-button>
			</div>
			<div style="margin-top: 20px;">
				<el-button size="mini" @click="nextPage()" v-preventReClick>{{$store.state.teachlanguagedata.nextpage}}</el-button>
			</div>
		</div>
		<Ceshi ref="Ceshi" canvasIdName="canvasIdName"></Ceshi>
	</div>
</template>

<script>
	import {
		showLoading,
		hideLoading
	} from '../../utils/Loading'
	import {
		getCorrectionFromPlan
	} from '../../api/Correct.js'
	import {
		queryListByDictValue
	} from '../../api/base.js'
	import Revision from "../Revision.vue"
	import Ceshi from '../Ceshi/index.vue'
	export default {
		name: 'Class',
		data() {
			return {
				pageId: null,
				pageIds: null, //
				cur_page: 0, //第几页
				img: null, //图片地址
				userId: null, //用户Id
				img_list: null, //
				teachId: JSON.parse(localStorage.getItem('teachlogin')).id, //老师Id
				teachLoginData: JSON.parse(localStorage.getItem('teachlogin')),
				setId: JSON.parse(localStorage.getItem("teachsetId")),
				planId: JSON.parse(localStorage.getItem("teachplan_id")),
				classId: JSON.parse(localStorage.getItem("teachclass_id")),
				canvesWight: 1200, //canvse 宽
				planData: JSON.parse(localStorage.getItem('teachQuestionSetInformation')), //作业相关信息
				deviationStutes: null, //偏移状态 老师偏移还是学生偏移
			}
		},
		components: {
			Ceshi
		},
		watch: {
		},
		mounted() {
		},
		methods: {
			mousedown(ev) {
				var divs = document.getElementById('Correct');
				//event的兼容性
				var ev = ev || event;
			
				//获取鼠标按下的坐标
				var x1 = ev.clientX;
				var y1 = ev.clientY;
			
				//获取元素的left，top值
				var l = divs.offsetLeft;
				var t = divs.offsetTop;
			
				//给可视区域添加鼠标的移动事件
				document.onmousemove = function(ev) {
			
					//event的兼容性
					var ev = ev || event;
			
					//获取鼠标移动时的坐标
					var x2 = ev.clientX;
					var y2 = ev.clientY;
			
					//计算出鼠标的移动距离
					var x = x2 - x1;
					var y = y2 - y1;
			
					//移动的数值与元素的left，top相加，得出元素的移动的距离
					var ls = x + l;
					//更改元素的left，top值
					divs.scrollLeft = ls
				}
			
				//清除
				document.onmouseup = function(ev) {
			
					document.onmousemove = null;
			
				}
			},
			getRollShow(){
				this.$store.commit('getRollShow', !this.$store.state.rollShow)
			},
			// 获取所有页面
			get_correction_from_plan(userId) {
				this.userId=userId
				getCorrectionFromPlan({
					"plan_id": this.planId,
					"userID": this.teachId,
					"setId": this.setId,
					"class_id": this.classId
				}).then(response => {
					this.corrected_users = response.data.corrected_users;
					this.unjoin_users = response.data.unjoin_users;
					this.pageId = response.data.pageId;
					this.pageIds = response.data.pageIds;
					this.cur_page = response.data.cur_page;
					this.img_list = response.data.img_list;
					this.img = response.data.img_src;
					this.$refs.Ceshi.init()
				})
			},
			//上一页
			previousPage() {
				this.$refs.Ceshi.previousPage()
			},
			//下一页
			nextPage() {
				this.$refs.Ceshi.nextPage()
			},
		}
	}
</script>

<style>

</style>
