<template>
	<div class="echartsPiechart">
		<div :id="Piechart" style="width: 200px;height: 200px;">
		</div>
		<div>
			<div class="Piechart_but" v-for="item in Piechartdata.colortext">
				<span :style="{'background':item.color}"></span>
				<span>{{item.text}}</span>
			</div>
		</div>
	</div>

</template>

<script>
var echarts = require('echarts')
require('echarts/lib/chart/bar')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
	export default {
		props: ['Piechart', 'Piechartdata'],
		data() {
			return {}
		},
		created() {
		},
		mounted() {
			this.drawLine()
		},
		methods: {
			drawLine() {
				let color=[]
				this.Piechartdata.colortext.forEach(item=>{
					color.push(item.color)
				})
				this.Piechartdata.color=color
				let dom = document.getElementById(this.Piechart)
				var myChart = echarts.init(dom);
				var option1 = {
					tooltip: {
						trigger: 'item'
					},
					color: this.Piechartdata.color,
					series: [{
						name: '完成情况',
						type: 'pie',
						radius: ["80%", ],
						label: { //展示文本设置 
							normal: {
								show: false, //展示
								position: 'outside' // outside表示文本显示位置为外部
							},
						},
						labelLine: { //引导线设置
							normal: {
								show: false, //引导线显示
							}
						},
						data: this.Piechartdata.data,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}]
				};
				myChart.setOption(option1);
			}
		}
	}
</script>

<style lang="scss">
	.echartsPiechart {
		display: flex;
		align-items: center;

		.Piechart_but {
			font-size: 12px;
			color: #505F79;
			line-height: 18px;
			margin-top: 10px;

			span:nth-child(1) {
				width: 10px;
				height: 10px;
				display: inline-block;
				margin-right: 4px;
			}
		}
	}
</style>
