<template>
  <!-- // 课中报告 -->
  <div class="study height85vh" id="study" style="position: relative;">
    <div class="study_top">
      <div class="study_top_top_con base_border_text_base">
        {{ $store.state.teachlanguagedata.subject }}:{{
          textbooklist.subjectName
        }}
        {{ $store.state.teachlanguagedata.Questionset }}:{{
          teachQuestionSetInformation.name
        }}
      </div>
      <div class="study_top_top">
        <span class="study_top_top_left base_border_text">{{
          $store.state.teachlanguagedata.CompletionRate
        }}</span>
        <span class="study_top_top_con"
          >{{ $store.state.teachlanguagedata.Submitted
          }}{{ queryClassScore.joinCount }}/{{ queryClassScore.totalCount
          }}{{ $store.state.teachlanguagedata.numberofpeople }}</span
        >
      </div>
      <div class="study_top_con">
        <Piechart
          Piechart="Piechart"
          ref="Piechartdataref"
          :Piechartdata="Piechartdata"
        ></Piechart>
        <Overview :Overviewdata="usersoce"></Overview>
      </div>

      <div class="study_top_top">
        <span class="study_top_top_left base_border_text">笔迹分析</span>
      </div>
      <el-button
        class="buttonTest"
        type="primary"
        @click="dialogPressureTest = true"
        >笔迹分析</el-button
      >
    </div>
    <div class="study_con" id="container">
      <div class="study_con_ceyan" v-if="teachplanIdPlankind == '测验'">
        <div class="study_test_con_class_top">
          <span class=" base_border_text">{{
            $store.state.teachlanguagedata.Classranking
          }}</span>
        </div>
        <div class="study_test_con_class_con">
          <el-table
            :data="queryClassRankLists"
            style="width:300px"
            :cell-style="{ 'text-align': 'center' }"
            row-key="id"
            :default-sort="{ prop: 'date', order: 'descending' }"
            :header-cell-style="{
              background: '#F3F5F9',
              color: '#6E7C94',
              'text-align': 'center',
            }"
          >
            <el-table-column
              type="index"
              :label="$store.state.teachlanguagedata.ranking"
              width="60"
            >
            </el-table-column>
            <el-table-column
              prop="userName"
              :label="$store.state.teachlanguagedata.name"
            >
            </el-table-column>
            <el-table-column
              prop="totalScore"
              sortable
              :label="$store.state.teachlanguagedata.fraction"
            >
              <template slot-scope="scope">
                <span @click="handleclick(scope.row)"
                  ><a>{{ scope.row.totalScore }}</a></span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <Report
        :Reportdata="tableData1"
        :plan_id="$route.params.plan_id"
      ></Report>
    </div>
    <el-dialog
      :visible.sync="usrDotshow"
      width="90%"
      height="90vh"
      class="my-info-dialog"
    >
      <div v-if="usrDotshow" class="textAlignCenter">
        <Correct ref="Correct" v-if="usrDotshow"></Correct>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogPressureTest" width="80%">
      <div slot="title">
        <span style="font-weight: 600;font-size: 20px;">压力测试图表</span>
      </div>
      <PressureTest></PressureTest>
    </el-dialog>
  </div>
</template>

<script>
import {
  gotUserJoinAllStation,
  queryQuestionBaseTreeList,
} from '../../api/Explain.js';
import { queryQuestionTreeList } from '../../api/Correct.js';
import {
  queryClassRankList,
  queryClassScoreDistribution,
} from '../../api/Study.js';
import PressureTest from '../../components/pressureTest.vue';
import Piechart from '../../components/echarts/Piechart.vue';
import Overview from '../../components/report/Overview.vue';
import Report from '../../components/report/Report.vue';
import baseJs from '../../utils/base/base.js';
import Correct from '../../components/OneStudents/index.vue';
export default {
  name: 'Study',
  components: {
    Piechart,
    Overview,
    Report,
    Correct,
    PressureTest,
  },
  data() {
    return {
      dialogPressureTest: false, //压力测试弹框是否显示
      usrDotshow: false,
      textbooklist: {},
      teachQuestionSetInformation: {},
      queryClassScore: '',
      queryClassRankLists: [],
      usersoce: [],
      Piechartdata: '',
      tableData1: [],
      teachplanIdPlankind: '',
    };
  },
  created() {
    this.teachplanIdPlankind = JSON.parse(
      localStorage.getItem('teachplanIdPlankind')
    );
    if (this.teachplanIdPlankind == '测验') {
      let Piechartdatas = {
        color: ['#57D9A3', '#4CAAFF', '#FFC400', '#FF7452'],
        colortext: [
          {
            color: '#4CAAFF',
            text: '',
          },
          {
            color: '#FFC400',
            text: '',
          },
          {
            color: '#FF7452',
            text: '',
          },
        ],
        data: [
          {
            value: '',
            name: '优秀',
          },
          {
            value: '',
            name: '普通',
          },
          {
            value: '',
            name: '不及格',
          },
        ],
      };
      let usersoces = [
        {
          name: '最高分',
          value: '',
          key: ['highestScore'],
          img: require('../../assets/img/iconuser3.png'),
        },
        {
          name: '最低分',
          value: '',
          key: ['lowestScore'],
          img: require('../../assets/img/iconuser1.png'),
        },
        {
          name: '平均分',
          value: '',
          key: ['average'],
          img: require('../../assets/img/iconuser2.png'),
        },
      ];
      this.usersoce = usersoces;
      this.Piechartdata = Piechartdatas;
      this.queryclassranklist();
    } else {
      let Piechartdatas = {
        color: ['#00C7E6', '#998DD9'],
        colortext: [
          {
            color: '#00C7E6',
            text: '已完成',
          },
          {
            color: '#998DD9',
            text: '未完成',
          },
        ],
        data: [
          {
            value: '',
            name: '已完成',
          },
          {
            value: '',
            name: '未完成',
          },
        ],
      };
      let usersoces = [
        {
          name: '按时完成（人）',
          value: '',
          key: ['finishOnTime', 'numberofpeople'],
          img: require('../../assets/img/iconuser.png'),
        },
      ];
      this.usersoce = usersoces;
      this.Piechartdata = Piechartdatas;
    }
    this.queryclassscoredistribution();
  },
  mounted() {
    this.textbooklist = JSON.parse(localStorage.getItem('textbooklist'));
    this.teachQuestionSetInformation = JSON.parse(
      localStorage.getItem('teachQuestionSetInformation')
    );
    this.getitems();
    this.queryclassranklist();
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    handleclick(data) {
      this.usrDotshow = true;
      this.$nextTick(() => {
        this.$refs.Correct.get_correction_from_plan(data.user_id);
      });
    },
    Mathqunati() {
      this.$nextTick(function() {
        //这里要注意，使用$nextTick等组件数据渲染完之后再调用MathJax渲染方法，要不然会获取不到数据
        if (this.commonsVariable.isMathjaxConfig) {
          //判断是否初始配置，若无则配置。
          this.commonsVariable.initMathjaxConfig();
        }
        this.commonsVariable.MathQueue('container');
        this.Mathqunatishow = true;
      });
    },
    queryclassscoredistribution() {
      let data = {
        parameters: {
          classId: JSON.parse(localStorage.getItem('teachclass_id')),
          planId: JSON.parse(localStorage.getItem('teachplan_id')),
        },
      };
      queryClassScoreDistribution(data).then((res) => {
        if (
          this.teachplanIdPlankind == '测验' &&
          localStorage.getItem('teachplan_id')
        ) {
          this.usersoce[0].value = res.data.result[0].maxScore;
          this.usersoce[1].value = res.data.result[0].minScore;
          this.usersoce[2].value = res.data.result[0].avgScore;
          this.Piechartdata.data[0].value = res.data.result[0].goodCount;
          this.Piechartdata.data[1].value =
            res.data.result[0].passCount - res.data.result[0].goodCount;
          this.Piechartdata.data[2].value =
            res.data.result[0].totalCount - res.data.result[0].passCount;
          let maxScore8 = Math.round(res.data.result[0].totalScore * 0.9);
          let maxScore6 = Math.round(res.data.result[0].totalScore * 0.6);
          this.Piechartdata.colortext[0].text =
            '优秀(' +
            maxScore8 +
            '≤分数≤' +
            res.data.result[0].totalScore +
            '分)';
          this.Piechartdata.colortext[1].text =
            '普通(' + maxScore6 + '≤分数<' + maxScore8 + '分)';
          this.Piechartdata.colortext[2].text =
            '不及格(0≤分数<' + maxScore6 + '分)';
          this.queryClassScore = res.data.result[0];
        } else {
          this.queryClassScore = res.data.result[0];
          this.usersoce[0].value = res.data.result[0].joinCount;
          this.Piechartdata.data[0].value = res.data.result[0].joinCount;
          this.Piechartdata.data[1].value =
            res.data.result[0].totalCount - res.data.result[0].joinCount;
        }
        this.$refs.Piechartdataref.drawLine();
      });
    },
    // 班级排行榜
    queryclassranklist() {
      let data = {
        parameters: {
          classId: JSON.parse(localStorage.getItem('teachclass_id')),
          planId: JSON.parse(localStorage.getItem('teachplan_id')),
        },
      };
      queryClassRankList(data).then((res) => {
        this.queryClassRankLists = res.data.result;
        this.Mathqunati();
      });
    },
    getitems() {
      let data = {
        planId: JSON.parse(localStorage.getItem('teachplan_id')),
        classId: JSON.parse(localStorage.getItem('teachclass_id')),
      };
      gotUserJoinAllStation(data).then((response) => {
        this.QueryQuestionBaseTreeList(response.data.result);
      });
    },
    QueryQuestionBaseTreeList(Adapter1Data) {
      let data = {
        onlyParent: 1,
        setId: this.getSetId(),
      };
      queryQuestionTreeList(data).then((response) => {
        this.tableData1 = baseJs.Adapter1(response.data.result, Adapter1Data);
      });
    },
    getSetId() {
      let data = this.$Download.getQuestionSet(
        JSON.parse(localStorage.getItem('teachQuestionSetInformation'))
          .resourceRelationList,
        'questionSet'
      );
      return data;
    },
    textcircled(val) {
      if (val) {
        let a = ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫'];
        let data = val.replace(/\\textcircled {(1)}/g, a[0]);
        data = data.replace(/\\textcircled {(2)}/g, a[1]);
        data = data.replace(/\\textcircled {(3)}/g, a[2]);
        data = data.replace(/\\textcircled {(4)}/g, a[3]);
        data = data.replace(/\\textcircled {(5)}/g, a[4]);
        data = data.replace(/\\textcircled {(6)}/g, a[5]);
        data = data.replace(/\\textcircled {(7)}/g, a[6]);
        data = data.replace(/\\textcircled {(8)}/g, a[7]);
        data = data.replace(/\\textcircled {(9)}/g, a[8]);
        data = data.replace(/\\textcircled {(10)}/g, a[9]);
        data = data.replace(/\\textcelsius/g, '度');
        data = data.replace(/&amp;nbsp;/g, ' ');
        return data;
      }
    },
  },
};
</script>

<style lang="scss">
.study_test_con_class_con {
  margin-top: 20px;
  max-height: 55vh;
  overflow: auto;
}

.study_test_con_fraction_h {
  font-size: 20px;
  color: #13264d;
  border-left: 6px solid #13264d;
  padding-left: 8px;
}

.study {
  width: 100%;
  overflow: auto;
  background-color: #ffffff;
  text-align: left;

  .study_top {
    margin-left: 20px;

    .study_top_top {
      text-align: left;
      margin-top: 24px;

      .study_top_top_con {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #8e9bb1;
        line-height: 20px;
      }
    }

    .study_top_con {
      display: flex;
      align-items: center;
    }
  }

  .study_con {
    display: flex;
    margin: 0 20px;

    .study_con_ceyan {
      margin-right: 24px;
    }
  }
}
.buttonTest {
  background-color: #13264d;
  margin: 10px 0;
  border: 0px;
}
.PressureTest {
  width: 80%;
  height: 80%;
  background-color: #ffffff;
  border: 1px solid #13264d;
}
</style>
